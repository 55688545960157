import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import './cosa.scss'
const Cosa = () => (
<Layout>
<main className="cosa-main">
    <SEO title="Consulente Marketing e Web Developer" />
    
    <div className="container first-container">
    <h1 className="main-title">Progetto una strategia...</h1>
    <p>Realizzo su misura per te un piano di Marketing studiato per far incontrare la qualità dei tuoi prodotti e servizi con una clientela che possa comprenderli ed apprezzarli</p>
    </div>
    <div className="container second-container">
    <h2 className="main-title second-title">...e la realizzo</h2>
    <p>basandomi su questo progetto di comunicazione, utilizzo gli strumenti che meglio si adattano alle caratteristiche della tua attività per rendere la tua presenza Online funzionale ai tuoi obbiettivi, ad esempio sviluppando un sito web ottimizzato ed occupandomi di Social, SEO ed Ads </p>
    </div>
   <div className="button-container" style={{
            height: `auto`,
            
          }}>
    <div className="button">
    <Link to="/contatti/">Ti interessa?</Link>
    </div>
    </div>
  </main>
  </Layout>
  
)

export default Cosa
